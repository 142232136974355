* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  cursor: none;
  font-smooth: auto;
  font-smooth: never;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-anchor: [auto];
  background: #1f1e1e fixed;
  overflow-x: hidden;
}

@media screen and (min-width: 1000px) {
  body {
    cursor: none;
  }
}

.body {
  opacity: 1;
}

@font-face {
  font-family: ITC Odyssee;
  src: url("LT_70781.39646709.woff") format("woff"), url("LT_70781.70c8d8f5.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ApercuProMono;
  src: url("ApercuProMono.e2d45741.woff2") format("woff2"), url("ApercuProMono.ef37da6f.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TIMMONS NY 2.0";
  src: url("TIMMONSNY20-Regular.b39beb7f.woff") format("woff"), url("TIMMONSNY20-Regular.075f24e3.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: optional;
}

.entree {
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: #000;
  flex-direction: column;
  place-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 16px;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.entree_end {
  animation: .325s ease-in forwards disapear;
}

@keyframes disapear {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.embleme {
  z-index: 95;
  justify-content: center;
  place-items: center;
  display: flex;
  position: fixed;
  bottom: 30px;
}

#insigne {
  width: 35px;
  fill: #c5c5c5;
  fill-rule: evenodd;
  justify-content: center;
  place-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  bottom: 0;
}

.logotype {
  opacity: 1;
  width: 100%;
  height: 100%;
  z-index: 96;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  justify-content: center;
  place-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
}

.typographie {
  color: #0000;
  margin: 0;
  padding: 0;
  font-family: ITC Odyssee;
  animation: .3s forwards opac_typo;
}

@keyframes opac_typo {
  from {
    color: #0000;
  }

  to {
    color: #fff;
  }
}

.banniere {
  height: 100vh;
  width: 100%;
  z-index: -99;
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  overflow-x: hidden;
}

.picture, .img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  overflow-x: hidden;
}

.svg_line {
  width: 100%;
}

.cursor {
  display: inherit;
}

@media screen and (max-width: 424px) {
  .cursor {
    display: none;
  }
}

.ligne {
  height: 1px;
  margin-bottom: 0;
  position: absolute;
  top: 40px;
  bottom: 100%;
}

.logo {
  width: auto;
  z-index: 0;
  fill: #ffe0dc;
  fill-rule: evenodd;
  transition: all .33s cubic-bezier(.075, .82, .165, 1);
  position: absolute;
  top: 22px;
}

.hide, .is_visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear .33s, opacity .33s linear;
}

.txt_mobile {
  padding: 0;
  position: sticky;
}

.txt_portfolio {
  color: #ffe0dc;
  margin-left: 30px;
  font-family: ApercuProMono;
  font-style: normal;
  font-weight: 100;
}

.txt_portfolio_menu {
  color: #ffe0dc;
  font-family: ApercuProMono;
  font-style: normal;
  font-weight: 100;
  position: sticky;
  top: 0;
}

#menu {
  cursor: none;
}

a, a:hover {
  color: #ffe0dc;
  text-decoration: none;
}

.sticky_main {
  width: 100%;
  height: 60px;
  z-index: 98;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  background-color: #0000000d;
  border-bottom: 62px solid #0000000d;
  display: flex;
  position: fixed;
  top: 0;
}

.menu_transformation {
  width: 100%;
  height: 60px;
  display: flex;
  position: sticky;
  top: 0;
}

.sticky_main2 {
  width: 100%;
  height: 100vh;
  z-index: 97;
  visibility: collapse;
  margin-top: 0;
  transition: opacity 2s linear;
  display: flex;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.menu_transformation2 {
  width: 100%;
  height: 100vh;
  z-index: 99;
  display: flex;
  position: sticky;
  top: 0;
}

.sticky_main2 .collapse {
  opacity: 1;
}

.no-padding {
  padding: 0;
}

.margin_top {
  margin-top: 15px;
}

.collapse {
  visibility: visible;
  animation: .3s cubic-bezier(.785, .135, .15, .86) opacity;
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.work {
  width: auto;
  height: auto;
  position: relative;
  top: 0;
}

.projet, .projet2 {
  min-height: 400px;
  max-height: 500px;
  height: 400px;
  margin-top: 0;
  margin-bottom: 0;
}

.txt_left {
  flex-flow: column;
  justify-content: flex-start;
  padding: 10px;
  display: flex !important;
}

.txt_right {
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  display: flex !important;
}

.txt_display {
  opacity: 0;
  flex-flow: column;
  justify-content: center;
  padding: 10px;
  transition: opacity .8s ease-in;
  display: flex !important;
}

.remove_text {
  opacity: 1;
  transition: opacity .33s linear;
  display: block;
}

.illustration {
  height: 10%;
}

.z_index {
  z-index: 0;
}

.display-4 {
  color: #ffe0dc;
  font-family: ApercuProMono;
}

.display-3 {
  color: #7d938a;
  font-family: ApercuProMono;
}

.h6 {
  color: #ffe0dc;
  font-family: ApercuProMono;
}

.h4, .h1 {
  color: #7d938a;
  font-family: ApercuProMono;
}

.arrplan_picture {
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.arrplan_img {
  height: 140%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  margin-left: 15px;
  padding-right: 30px;
  position: absolute;
  top: -80px;
  left: 0;
  overflow: hidden;
}

.arrplan_img2 {
  height: 140%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
  margin-left: 15px;
  padding-right: 30px;
  position: absolute;
  top: -80px;
  left: 0;
  overflow-x: hidden;
}

.arrplan_picture2 {
  object-fit: cover;
  z-index: -1;
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.description_projet {
  word-spacing: -.2em;
  color: #ffe0dc;
  font-family: ApercuProMono;
  font-style: normal;
  font-weight: 100;
}

.reseaux_sociaux {
  width: 45px;
  background-color: none;
  flex-flow: column;
  place-content: center space-evenly;
  align-items: center;
  margin-left: 8px;
  padding: 2px;
}

.icon_footer {
  fill: #0000;
  fill-rule: evenodd;
  stroke-width: 0;
  stroke: #ffe0dc;
  fill: #ffe0dc;
  width: 100%;
  padding: 5px;
}

.is_displayed {
  opacity: 1;
  display: block;
}

.not_displayed {
  opacity: 1;
  display: flex;
}

.mobile_icons {
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.mobile_icons2 {
  height: 50px;
  width: 100vw;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.icon_footer2 {
  fill: #0000;
  fill-rule: evenodd;
  stroke-width: 1px;
  stroke: #ffe0dc;
  width: 40px;
  height: 40px;
  padding: 5px;
  transition: all .5s cubic-bezier(.075, .82, .165, 1);
}

.ajustement_gauche {
  z-index: 99;
  margin-left: 10px;
}

.ajustement_droit {
  z-index: 99;
  margin-right: 14px;
}

#mail:hover, #linkdin:hover, #instagram:hover, #facebook:hover {
  fill: #ffe0dc;
}

.txt_icon_credit {
  color: #ffe0dc;
  align-items: center;
  font-family: ApercuProMono;
  font-style: normal;
  font-weight: 100;
}

.txt_credits {
  color: #ebceca;
  text-align: start;
  margin: 0;
  font-family: ApercuProMono;
  font-size: .8em;
  font-style: normal;
  font-weight: 100;
}

.credits {
  background-color: none;
  flex: 1;
  padding: 5px 40px 20px 8px;
}

.footer {
  z-index: 95;
  opacity: 0;
  margin-top: 15px;
  transition: opacity .5s ease-in;
  position: relative;
  bottom: 0;
}

.scroll-footer, .scroll-description, .scroll-projet {
  opacity: 1;
}

.numeroprojet {
  opacity: 0;
  transform-origin: top;
  transform-style: preserve-3d;
  transition: opacity .8s cubic-bezier(.215, .61, .355, 1), transform .8s cubic-bezier(.215, .61, .355, 1);
  display: block;
  transform: translateY(100%)rotateX(-89deg);
}

.title {
  width: 100%;
  height: 100%;
}

.title.is-inview .numeroprojet {
  opacity: 1;
  transition-delay: .3s;
  transform: none;
}

.numeroprojet2 {
  opacity: 0;
  transform-origin: top;
  transform-style: preserve-3d;
  transition: opacity .8s cubic-bezier(.215, .61, .355, 1), transform .8s cubic-bezier(.215, .61, .355, 1);
  display: block;
  transform: translateY(100%)rotateX(-89deg);
}

.title.is-inview .numeroprojet2 {
  opacity: 1;
  transition-delay: .4s;
  transform: none;
}

.numeroprojet3 {
  opacity: 0;
  transform-origin: top;
  transform-style: preserve-3d;
  transition: opacity .8s cubic-bezier(.215, .61, .355, 1), transform .8s cubic-bezier(.215, .61, .355, 1);
  display: block;
  transform: translateY(100%)rotateX(-89deg);
}

.title.is-inview .numeroprojet3 {
  opacity: 1;
  transition-delay: .6s;
  transform: none;
}

.numeroprojet4 {
  opacity: 0;
  transform-origin: top;
  transform-style: preserve-3d;
  transition: opacity .8s cubic-bezier(.215, .61, .355, 1), transform .8s cubic-bezier(.215, .61, .355, 1);
  display: block;
  transform: translateY(100%)rotateX(-89deg);
}

.title.is-inview .numeroprojet4 {
  opacity: 1;
  transition-delay: .7s;
  transform: none;
}

article {
  margin-top: 50px;
}

.loadtransition {
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: #000;
  flex-direction: column;
  place-content: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.load2transition {
  animation: .5s ease-in forwards disapear2;
}

@keyframes disapear2 {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@media screen and (max-width: 424px) {
  .typographie {
    font-size: 1.5em;
  }

  .not_displayed {
    opacity: 0;
    display: none;
  }

  .hide {
    visibility: hidden;
    opacity: 0;
  }

  .main-profil {
    padding: 50px 30px 0;
    font-size: .8em;
  }

  article {
    margin-top: 50px;
  }

  .projet {
    min-height: 400px;
    max-height: 500px;
    height: 400px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .title {
    width: 100%;
    height: 100%;
  }

  a {
    color: #ffe0dc;
    text-decoration: none;
  }

  .numeroprojet {
    transform-origin: top;
    transform-style: preserve-3d;
    transition: opacity .8s cubic-bezier(.215, .61, .355, 1), transform .8s cubic-bezier(.215, .61, .355, 1);
    display: block;
  }

  .arrplan_picture2 {
    object-fit: cover;
    z-index: -1;
    width: 100%;
    height: 400px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .typographie {
    font-size: 1.8em;
  }

  article {
    height: 100vh;
    background-color: #fff;
    margin-top: 0;
  }

  .projet {
    max-height: 50vh;
    height: 50vh;
    margin-top: 0;
    margin-bottom: 0;
  }

  .arrplan_img1, .arrplan_img2 {
    object-fit: cover;
    z-index: -1;
    width: 100%;
    height: 50vh;
    align-content: flex-end;
    align-items: flex-end;
    position: absolute;
    left: 0;
    overflow: hidden;
    transform: translate(0%, 50%);
  }

  .numeroprojet {
    font-size: 8em;
    position: absolute;
    top: 65px;
  }

  .numeroprojet2 {
    font-size: 4em;
    position: absolute;
    top: 225px;
  }

  .numeroprojet3 {
    font-size: 1.5em;
    position: absolute;
    top: 30px;
  }

  .numeroprojet4 {
    font-size: 2em;
    position: absolute;
    top: 60px;
  }
}

@media screen and (min-width: 1200px) {
  .typographie {
    font-size: 2em;
  }
}

@media screen and (max-width: 767px) {
  .remove_text {
    opacity: 0;
    display: none;
  }
}

@media screen and (min-width: 425px) {
  .is_displayed {
    opacity: 0;
    display: none;
  }

  .is_visible {
    visibility: hidden;
    opacity: 0;
  }
}

/*# sourceMappingURL=index.473df87d.css.map */
